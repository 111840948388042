// api.js
import axios from "axios";
import { includes } from "lodash";

const getENVwithOutProd = () => {
  const hosturl = window.location.host.split(".");
  if (includes(hosturl, "staging")) {
    return ".staging";
  } else if (includes(hosturl, "prestage")) {
    return ".prestage";
  } else if (includes(hosturl, "dev")) {
    return ".dev";
  } else if (includes(hosturl, "localhost:3000")) {
    return ".dev";
  } else if (includes(hosturl, "qa")) {
    return ".qa";
  } else if (includes(hosturl, "playground")) {
    return ".playground";
  } else {
    return "";
  }
};
let dc = window.location.pathname.split("/")[2];
const dynamicUrl =
  "https://" + dc + getENVwithOutProd() + ".api.getqatalyst.io/v1/";
const api = axios.create({
  // Set your base API URL here
  baseURL: dynamicUrl,
});

// Request interceptor
api.interceptors.request.use(
  config => {
    // Modify the request config before sending the request
    // For example, you can add headers or perform other actions

    // Add an authentication token to the request header if needed
    // const authToken = getAuthToken();
    // if (authToken) {
    //   config.headers['Authorization'] = `Bearer ${authToken}`;
    // }
    const sessionParam =
      sessionStorage.getItem("user-params") &&
      JSON.parse(sessionStorage.getItem("user-params"));
    // You can also show a loading spinner or perform other actions here
    config.headers["workspace-id"] = sessionParam.workspace_id;
    return config;
  },
  error => {
    // Handle request errors (if any)
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  response => {
    // Handle successful responses
    // You can modify the response data if needed
    return response;
  },
  error => {
    // Handle response errors
    // For example, you can check the status code and perform actions accordingly

    // Handle 401 Unauthorized (redirect to login page, for example)
    // if (error.response && error.response.status === 401) {
    //   logoutUser();
    //   window.location.href = '/login';
    // }

    // You can also show a toast message or perform other actions here

    return Promise.reject(error);
  }
);

export default api;
