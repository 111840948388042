import React, { Fragment, useMemo, useState } from "react";
import { useMeasure } from "react-use";
import {
  Box as BoxChakra,
  Divider as DividerChakra,
  Text as TextChakra,
} from "@chakra-ui/react";
import {
  selectMessagesUnreadCountByPeerID,
  selectMessagesUnreadCountByRole,
  selectRemotePeers,
  selectUnreadHMSMessagesCount,
  useHMSStore,
} from "@100mslive/react-sdk";
import { CheckIcon } from "@100mslive/react-icons";
import { Box, Flex, Tooltip } from "@100mslive/react-ui";
import { ParticipantSearch } from "../Header/ParticipantList";
import { useFilteredRoles } from "../../common/hooks";
import styles from "./Chat.module.css";

const ChatDotIcon = () => {
  return <Box css={{ size: "$6", bg: "#ffffff", mx: "$2", r: "$round" }} />;
};

const SelectorItem = ({ value, active, onClick, unreadCount }) => {
  return (
    <BoxChakra
      data-testid="chat_members"
      onClick={onClick}
      className={styles["selector-item"]}
    >
      <TextChakra className={styles["selector-item__text"]} variant="sm">
        {value}
      </TextChakra>
      <Flex>
        {unreadCount > 0 && (
          <Tooltip title={`${unreadCount} unread`}>
            <Box>
              <ChatDotIcon />
            </Box>
          </Tooltip>
        )}
        {active && <CheckIcon color="#15B79E" width={16} height={16} />}
      </Flex>
    </BoxChakra>
  );
};

const SelectorHeader = React.memo(({ children }) => {
  return (
    <BoxChakra className={styles["selector-header"]}>
      <DividerChakra className={styles["selector-header__divider"]} />
      <TextChakra className={styles["selector-header__text"]}>
        {children}
      </TextChakra>
    </BoxChakra>
  );
});

const Everyone = React.memo(({ onSelect, active }) => {
  const unreadCount = useHMSStore(selectUnreadHMSMessagesCount);
  return (
    <SelectorItem
      value="Everyone"
      active={active}
      unreadCount={unreadCount}
      onClick={() => {
        onSelect({ role: "", peerId: "", selection: "Everyone" });
      }}
    />
  );
});

const RoleItem = React.memo(({ onSelect, role, active }) => {
  const unreadCount = useHMSStore(selectMessagesUnreadCountByRole(role));
  return (
    <SelectorItem
      value={role}
      active={active}
      unreadCount={unreadCount}
      onClick={() => {
        onSelect({ role: role, selection: role });
      }}
    />
  );
});

const PeerItem = ({ onSelect, peerId, name, active }) => {
  const unreadCount = useHMSStore(selectMessagesUnreadCountByPeerID(peerId));
  return (
    <SelectorItem
      value={name}
      active={active}
      unreadCount={unreadCount}
      onClick={() => {
        onSelect({ role: "", peerId, selection: name });
      }}
    />
  );
};

const VirtualizedSelectItemList = ({
  peers,
  selectedRole,
  selectedPeerId,
  searchValue,
  onSelect,
}) => {
  const [ref, { width, height }] = useMeasure();
  const roles = useFilteredRoles();

  const profileData = sessionStorage.getItem("profile-data") ? JSON?.parse(sessionStorage?.getItem("profile-data")) : false ;

  const filteredPeers = useMemo(
    () =>
      peers.filter(
        peer =>
          // Search should be empty or search phrase should be included in name
          (!searchValue ||
            peer.name.toLowerCase().includes(searchValue.toLowerCase())) &&
          // roleName should not be 'attendee' or 'attendee-waitingroom'
          (profileData?.role !== "observer" ||
            !["attendee", "attendee-waitingroom"].includes(peer.roleName))
      ),
    [peers, searchValue, profileData]
  );

  const filterRoles = useMemo(() => {
    return roles.filter(
      role =>
        !searchValue || role.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [roles, searchValue]);
  const listItems = useMemo(() => {
    const selectItems = [
      <Everyone
        onSelect={onSelect}
        active={!selectedRole && !selectedPeerId}
      />,
    ];

    filterRoles.length > 0 &&
      selectItems.push(<SelectorHeader>Roles</SelectorHeader>);
    filterRoles.forEach(userRole =>
      selectItems.push(
        <RoleItem
          key={userRole}
          active={selectedRole === userRole}
          role={userRole}
          onSelect={onSelect}
        />
      )
    );

    {
      profileData?.role !== "observer" &&
        filteredPeers.length > 0 &&
      selectItems.push(<SelectorHeader>Participants</SelectorHeader>);
    filteredPeers.forEach(peer =>
      selectItems.push(
        <PeerItem
          key={peer.id}
          name={peer.name}
          peerId={peer.id}
            active={peer.id === selectedPeerId}
          onSelect={onSelect}
        />
        )
      );
    }

    return selectItems;
  }, [onSelect, selectedRole, selectedPeerId, roles, filteredPeers]);

  return (
    <BoxChakra className={styles["dropdown__list-item"]} ref={ref}>
      {listItems.map(ele => ele)}
    </BoxChakra>
  );
};

export const ChatSelector = ({ role, peerId, onSelect }) => {
  const peers = useHMSStore(selectRemotePeers);
  const [search, setSearch] = useState("");

  return (
    <Fragment>
      {peers.length > 0 && (
        <Box
          css={{
            color: "$black",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <ParticipantSearch
            onSearch={setSearch}
            placeholder="Search participants"
          />
        </Box>
      )}
      <VirtualizedSelectItemList
        selectedRole={role}
        selectedPeerId={peerId}
        onSelect={onSelect}
        peers={peers}
        searchValue={search}
      />
    </Fragment>
  );
};
