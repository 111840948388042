import React, { useState } from "react";
import { Flex, flexCenter, styled, Text } from "@100mslive/react-ui";
import PreviewName from "./PreviewName";
import { useNavigation } from "../hooks/useNavigation";

const PreviewJoin = ({
  token,
  onJoin,
  env,
  skipPreview,
  initialName,
  asRole,
}) => {
  const [name, setName] = useState(initialName);
  const [email, setEmail] = useState("");
  const navigate = useNavigation();

  const enableJoin = token => {
    if (token) {
      navigate("/conference/" + token);
    }
  };

  return (
    <Container>
      <Text variant="h4" css={{ wordBreak: "break-word", textAlign: "center" }}>
        Get Started
      </Text>

      <Flex
        align="center"
        justify="center"
        css={{
          "@sm": { width: "100%" },
          flexDirection: "column",
        }}
      >
        <PreviewName
          name={name}
          email={email}
          onChange={setName}
          onEmailChange={setEmail}
          enableJoin={enableJoin}
        />
      </Flex>
    </Container>
  );
};

const Container = styled("div", {
  width: "100%",
  ...flexCenter,
  flexDirection: "column",
  px: "$10",
});

export default PreviewJoin;
