import React, { useCallback, useEffect, useRef, useState } from "react";
import { IoSend } from "react-icons/io5";
import {
  Flex as FlexChakra,
  IconButton as IconButtonChakra,
} from "@chakra-ui/react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useHMSActions } from "@100mslive/react-sdk";
import { EmojiIcon } from "@100mslive/react-icons";
import { Box, IconButton, Popover, styled } from "@100mslive/react-ui";
import { ToastManager } from "../Toast/ToastManager";
import { useChatDraftMessage } from "../AppData/useChatState";
import { useEmojiPickerStyles } from "./useEmojiPickerStyles";
import styles from "./Chat.module.css";

const TextArea = styled("textarea", {
  width: "100%",
  bg: "transparent",
  borderRadius: "0.5rem !important",
  border: "1px solid #d0d5dd !important",
  background: "#fff !important",
  boxShadow: " 0 1px 2px #1018280d !important",
  color: "#0c111d !important",
  borderColor: "inherit !important",
  height: "44px",
  resize: "none",
  lineHeight: "1rem",
  padding: "0.8rem 0.3rem",
  top: "$3",
  "&:focus": {
    borderColor: "#99f6e0 !important",
    boxShadow: "0 0 0 4px #15b79e3d !important",
    background: "#fff !important",
  },

  "&:hover": {
    borderColor: "#98a2b3 !important",
  },
});

function EmojiPicker({ onSelect }) {
  const [showEmoji, setShowEmoji] = useState(false);
  const ref = useEmojiPickerStyles(showEmoji);
  return (
    <Popover.Root open={showEmoji} onOpenChange={setShowEmoji}>
      <Popover.Trigger asChild css={{ appearance: "none" }}>
        <IconButton as="div">
          <EmojiIcon />
        </IconButton>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          alignOffset={-40}
          sideOffset={16}
          align="end"
          css={{
            p: 0,
          }}
        >
          <Box
            css={{
              minWidth: 352,
              minHeight: 435,
            }}
            ref={ref}
          >
            <Picker
              onEmojiSelect={onSelect}
              data={data}
              previewPosition="none"
              skinPosition="search"
            />
          </Box>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

export const ChatFooter = ({ role, peerId, onSend, children }) => {
  const hmsActions = useHMSActions();
  const inputRef = useRef(null);
  const [draftMessage, setDraftMessage] = useChatDraftMessage();

  const sendMessage = useCallback(async () => {
    const message = inputRef.current.value;
    if (!message || !message.trim().length) {
      return;
    }
    try {
      if (role) {
        await hmsActions.sendGroupMessage(message, [role]);
      } else if (peerId) {
        await hmsActions.sendDirectMessage(message, peerId);
      } else {
        await hmsActions.sendBroadcastMessage(message);
      }
      inputRef.current.value = "";
      setTimeout(() => {
        onSend();
      }, 0);
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
  }, [role, peerId, hmsActions, onSend]);

  useEffect(() => {
    const messageElement = inputRef.current;
    if (messageElement) {
      messageElement.value = draftMessage;
    }
  }, [draftMessage]);

  useEffect(() => {
    const messageElement = inputRef.current;
    return () => {
      setDraftMessage(messageElement?.value || "");
    };
  }, [setDraftMessage]);

  return (
    <FlexChakra className={styles["chat-footer"]}>
      {children}
      <TextArea
        placeholder="Write something here"
        className={styles["chat-footer__text-area"]}
        ref={inputRef}
        autoFocus
        onKeyPress={async event => {
          if (event.key === "Enter") {
            if (!event.shiftKey) {
              event.preventDefault();
              await sendMessage();
            }
          }
        }}
        autoComplete="off"
        aria-autocomplete="none"
        onPaste={e => e.stopPropagation()}
        onCut={e => e.stopPropagation()}
        onCopy={e => e.stopPropagation()}
      />
      <IconButtonChakra
        className={styles["send_msg_btn"]}
        onClick={sendMessage}
        aria-label="send_msg_btn"
      >
        <IoSend />
      </IconButtonChakra>
    </FlexChakra>
  );
};
