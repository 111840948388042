import React, { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { Button, Input, styled } from "@100mslive/react-ui";
import api from "../../Api";
import { ToastManager } from "../Toast/ToastManager";
import { isStreamingKit } from "../../common/utils";

const PreviewName = ({
  name,
  email,
  onChange,
  onEmailChange,
  onJoin,
  enableJoin,
}) => {
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    // Function to parse query parameters and convert them to JSON
    const getQueryParamsAsJson = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const queryParamsObj = {};
      for (let param of searchParams.entries()) {
        const [key, value] = param;
        queryParamsObj[key] = value;
      }
      return queryParamsObj;
    };

    // Call the function and update the state with the JSON object
    setQueryParams(getQueryParamsAsJson());
    sessionStorage.setItem(
      "user-params",
      JSON.stringify(getQueryParamsAsJson())
    );
  }, []);

  const formSubmit = e => {
    const newVal = {
      name: name.trim(),
      email: email.trim(),
      meeting_id: queryParams.room_id,
      dc_code: window.location.pathname.split("/")[2],
    };

    const payload = { ...queryParams, ...newVal };
    e.preventDefault();
    api
      .post("/meeting-token", payload)
      .then(response => {
        // Handle the successful response
        console.log("Post successful!", response.data);
        const tokenData = jwtDecode(response.data.token);
        sessionStorage.setItem("profile-data", JSON.stringify(tokenData));
        sessionStorage.setItem("auth-token", response.data.token);
        // You can reset the form or show a success message here
        enableJoin(response.data.token);
      })
      .catch(error => {
        // Handle errors if needed
        console.error("API Error:", error);
        ToastManager.addToast({
          title:
            error.response.data.message ||
            error.response.data.email ||
            "Oops! Something went wrong",
          variant: "error",
        });
        // You can show an error message here
      });
  };

  const handleNameChange = name => {
    // Check if the input starts with a space
    if (name.startsWith(" ")) {
      onChange(name.trimLeft());
    } else {
      onChange(name);
    }
  };

  return (
    <Form onSubmit={formSubmit}>
      <Input
        required
        id="email"
        css={{ w: "100%", mb: "12px" }}
        value={email}
        onChange={e => onEmailChange(e.target.value.toLowerCase())}
        placeholder="Enter your email"
        autoFocus
        autoComplete="email"
      />
      <Input
        required
        id="name"
        css={{ w: "100%", mb: "12px" }}
        value={name}
        onChange={e => handleNameChange(e.target.value)}
        placeholder="Enter your name"
        autoComplete="name"
      />
      <Button type="submit" disabled={!name || !email}>
        {isStreamingKit() ? "Join Studio" : "Join Room"}
      </Button>
    </Form>
  );
};

const Form = styled("form", {
  width: "100%",
  display: "block",
  alignItems: "center",
  gap: "$4",
  mt: "$10",
  mb: "$10",
});

export default PreviewName;
