import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDebounce, useMeasure } from "react-use";
import { FixedSizeList } from "react-window";
import { Box as BoxChakra, Input as InputChakra } from "@chakra-ui/react";
import {
  selectLocalPeerRoleName,
  selectPeerCount,
  selectPeerMetadata,
  selectPeersByRole,
  useHMSActions,
  useHMSStore,
  useParticipants,
} from "@100mslive/react-sdk";
import {
  CrossIcon,
  HandRaiseIcon,
  PeopleIcon,
  SearchIcon,
} from "@100mslive/react-icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Input,
  Text,
  textEllipsis,
} from "@100mslive/react-ui";
import IconButton from "../../IconButton";
import { ConnectionIndicator } from "../Connection/ConnectionIndicator";
import { RoleChangeModal } from "../RoleChangeModal";
import {
  useIsSidepaneTypeOpen,
  useSidepaneToggle,
} from "../AppData/useSidepane";
import { capitalizeWords } from "../../common/utils";
import { SIDE_PANE_OPTIONS } from "../../common/constants";
import "./ParticipantList.css";
import styles from "../Chat/Chat.module.css";

export const ParticipantList = () => {
  const [filter, setFilter] = useState();
  const { participants, isConnected, peerCount } = useParticipants(filter);
  const [selectedPeerId, setSelectedPeerId] = useState(null);
  const toggleSidepane = useSidepaneToggle(SIDE_PANE_OPTIONS.PARTICIPANTS);
  const onSearch = useCallback(value => {
    setFilter(filterValue => {
      if (!filterValue) {
        filterValue = {};
      }
      filterValue.search = value;
      return { ...filterValue };
    });
  }, []);
  if (peerCount === 0) {
    return null;
  }

  return (
    <Fragment>
      <Flex direction="column" css={{ size: "100%" }}>
        <Flex align="center" css={{ w: "100%", mb: "$10" }}>
          <Text css={{ fontWeight: "$semiBold", mr: "$4" }}>
            Participants (Everyone)
          </Text>
          <IconButton
            onClick={toggleSidepane}
            css={{ w: "$11", h: "$11", ml: "auto" }}
          >
            <CrossIcon />
          </IconButton>
        </Flex>
        {!filter?.search && participants.length === 0 ? null : (
          <ParticipantSearchNew onSearch={onSearch} />
        )}
        {participants.length === 0 && (
          <Flex align="center" justify="center" css={{ w: "100%", p: "$8 0" }}>
            <Text variant="sm">
              {!filter ? "No participants" : "No matching participants"}
            </Text>
          </Flex>
        )}
        <VirtualizedParticipants
          participants={participants.filter(
            participant => participant.roleName !== "recorder"
          )}
          isConnected={isConnected}
          setSelectedPeerId={setSelectedPeerId}
        />
      </Flex>
      {selectedPeerId && (
        <RoleChangeModal
          peerId={selectedPeerId}
          onOpenChange={value => {
            !value && setSelectedPeerId(null);
          }}
        />
      )}
    </Fragment>
  );
};

export const ParticipantCount = () => {
  let peerCount = useHMSStore(selectPeerCount);
  const toggleSidepane = useSidepaneToggle(SIDE_PANE_OPTIONS.PARTICIPANTS);
  const isParticipantsOpen = useIsSidepaneTypeOpen(
    SIDE_PANE_OPTIONS.PARTICIPANTS
  );
  const hasWaitingRoomPeers = useHMSStore(
    selectPeersByRole("attendee-waitingroom")
  ).length;
  const hasRecorder = useHMSStore(selectPeersByRole("recorder")).length;

  useEffect(() => {
    if (isParticipantsOpen && peerCount === 0) {
      toggleSidepane();
    }
  }, [isParticipantsOpen, peerCount, toggleSidepane]);

  if (peerCount === 0) {
    return null;
  }
  if (hasWaitingRoomPeers > 0) {
    peerCount = peerCount - hasWaitingRoomPeers;
  }
  if (hasRecorder > 0) {
    peerCount = peerCount - hasRecorder;
  }

  return (
    <IconButton
      css={{
        w: "auto",
        p: "$4",
        h: "auto",
      }}
      onClick={() => {
        if (peerCount > 0) {
          toggleSidepane();
        }
      }}
      active={!isParticipantsOpen}
      data-testid="participant_list"
    >
      <PeopleIcon />
      <Text variant="sm" css={{ mx: "$4", c: "inherit" }}>
        {peerCount}
      </Text>
    </IconButton>
  );
};

function itemKey(index, data) {
  return data.participants[index].id;
}

const VirtualizedParticipants = ({
  participants,
  isConnected,
  setSelectedPeerId,
}) => {
  const [ref, { width, height }] = useMeasure();
  return (
    <Box
      ref={ref}
      css={{
        flex: "1 1 0",
        mr: "-$10",
      }}
    >
      <FixedSizeList
        itemSize={68}
        itemData={{ participants, isConnected, setSelectedPeerId }}
        itemKey={itemKey}
        itemCount={participants.length}
        width={width}
        height={height}
      >
        {VirtualisedParticipantListItem}
      </FixedSizeList>
    </Box>
  );
};

const VirtualisedParticipantListItem = React.memo(({ style, index, data }) => {
  return (
    <div style={style} key={data.participants[index].id}>
      <Participant
        peer={data.participants[index]}
        isConnected={data.isConnected}
        setSelectedPeerId={data.setSelectedPeerId}
      />
    </div>
  );
});

const Participant = ({ peer, isConnected, setSelectedPeerId }) => {
  const localPeerRole = useHMSStore(selectLocalPeerRoleName);
  const hmsActions = useHMSActions();
  return (
    <Fragment>
      <Flex
        key={peer.id}
        css={{ w: "100%", py: "$4", pr: "$10" }}
        align="center"
        data-testid={"participant_" + peer.name}
      >
        <Avatar
          name={peer.name}
          css={{
            position: "unset",
            transform: "unset",
            mr: "$8",
            fontSize: "$sm",
            size: "$12",
            p: "$4",
          }}
        />
        <Flex direction="column" css={{ flex: "1 1 0" }}>
          <Text
            variant="md"
            css={{ ...textEllipsis(150), fontWeight: "$semiBold" }}
          >
            {capitalizeWords(peer.name)}
          </Text>
          <Text variant="sub2">{peer.roleName}</Text>
        </Flex>
        {peer.roleName === "attendee-waitingroom" &&
          ["organizer", "moderator", "organizer-hide"].includes(
            localPeerRole
          ) && (
            <Flex css={{ justifyContent: "flex-end" }}>
              <Button
                className="participant_denyBtn"
                onClick={async () => {
                  try {
                    const reason = "Organiser rejected the request";
                    await hmsActions.removePeer(peer.id, reason);
                  } catch (error) {
                    // Permission denied or invalid peer ID or not connected to room
                    console.error(error);
                  }
                }}
              >
                Deny
              </Button>
              <Button
                className="participant_admitBtn"
                onClick={async () => {
                  await hmsActions.changeRoleOfPeersWithRoles(
                    ["attendee-waitingroom"],
                    "attendee"
                  );
                }}
              >
                Admit
              </Button>
            </Flex>
          )}
        {isConnected && (
          <ParticipantActions
            peerId={peer.id}
            role={peer.roleName}
            onSettings={() => {
              setSelectedPeerId(peer.id);
            }}
          />
        )}
      </Flex>
    </Fragment>
  );
};

/**
 * shows settings to change for a participant like changing their role
 */
const ParticipantActions = React.memo(({ onSettings, peerId, role }) => {
  const isHandRaised = useHMSStore(selectPeerMetadata(peerId))?.isHandRaised;

  return (
    <Flex align="center" css={{ flexShrink: 0 }}>
      <ConnectionIndicator peerId={peerId} />
      {isHandRaised && <HandRaiseIcon />}
    </Flex>
  );
});

export const ParticipantSearch = ({ onSearch, placeholder }) => {
  const [value, setValue] = React.useState("");
  useDebounce(
    () => {
      onSearch(value);
    },
    300,
    [value, onSearch]
  );
  return (
    <BoxChakra className={styles["particiapnt-list"]}>
      <BoxChakra className={styles["search-cotainer"]}>
        <SearchIcon />
      </BoxChakra>
      <InputChakra
        className={styles["search-input"]}
        type="text"
        placeholder={placeholder || "Find what you are looking for"}
        value={value}
        onKeyDown={event => {
          event.stopPropagation();
        }}
        onChange={event => {
          setValue(event.currentTarget.value);
        }}
        autoComplete="off"
        aria-autocomplete="none"
        borderColor="#2D3440"
        _focus={{ borderColor: "#2471ED" }}
      />
    </BoxChakra>
  );
};

const ParticipantSearchNew = ({ onSearch, placeholder }) => {
  const [value, setValue] = React.useState("");
  useDebounce(
    () => {
      onSearch(value);
    },
    300,
    [value, onSearch]
  );
  return (
    <Box css={{ p: "$4 0", my: "$8", position: "relative" }}>
      <Box
        css={{
          position: "absolute",
          left: "$4",
          top: "$2",
          transform: "translateY(50%)",
          color: "$textMedEmp",
        }}
      >
        <SearchIcon />
      </Box>
      <Input
        type="text"
        placeholder={placeholder || "Find what you are looking for"}
        css={{ w: "100%", pl: "$14" }}
        value={value}
        onKeyDown={event => {
          event.stopPropagation();
        }}
        onChange={event => {
          setValue(event.currentTarget.value);
        }}
        autoComplete="off"
        aria-autocomplete="none"
      />
    </Box>
  );
};
