import React, { useState } from "react";
import { Flex as FlexChakra, Text as TextChakra } from "@chakra-ui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CrossIcon,
} from "@100mslive/react-icons";
import { Box, Dropdown, Flex, IconButton } from "@100mslive/react-ui";
import { ChatSelector } from "./ChatSelector";
import { useSidepaneToggle } from "../AppData/useSidepane";
import { SIDE_PANE_OPTIONS } from "../../common/constants";
import styles from "./Chat.module.css";

export const ChatHeader = React.memo(
  ({
    selection,
    selectorOpen,
    onToggle,
    onSelect,
    role,
    peerId,
    open,
    setOpen,
  }) => {
    const toggleChat = useSidepaneToggle(SIDE_PANE_OPTIONS.CHAT);
    return (
      <Flex
        onClick={onToggle}
        align="center"
        css={{
          color: "$textPrimary",
          h: "$16",
          mb: "$2",
        }}
      >
        <TextChakra className={styles["chat-header__heading"]}>
          Chat{" "}
        </TextChakra>
        <Dropdown.Root open={open} onOpenChange={value => setOpen(value)}>
          <Dropdown.Trigger
            asChild
            data-testid="participant_list_filter"
            css={{
              border: "1px solid #667085",
              r: "$0",
              p: "$2 $4",
              ml: "$8",
            }}
            tabIndex={0}
          >
            <FlexChakra className={styles["chat-header__dropdown-btn"]}>
              <TextChakra className={styles["chat-header__dropdown-text"]}>
                {selection}
              </TextChakra>
              <Box css={{ ml: "$2", color: "#667085" }}>
                {open ? (
                  <ChevronUpIcon width={14} height={14} />
                ) : (
                  <ChevronDownIcon width={14} height={14} />
                )}
              </Box>
            </FlexChakra>
          </Dropdown.Trigger>
          <Dropdown.Content
            css={{
              w: "$64",
              overflow: "hidden",
              maxHeight: "unset",
              bg: "#ffffff",
              color: "$black",
            }}
            align="start"
            sideOffset={8}
          >
            <ChatSelector onSelect={onSelect} role={role} peerId={peerId} />
          </Dropdown.Content>
        </Dropdown.Root>
        <IconButton
          css={{ ml: "auto" }}
          onClick={e => {
            e.stopPropagation();
            selectorOpen ? onToggle() : toggleChat();
          }}
          data-testid="close_chat"
        >
          <CrossIcon color="#667085" />
        </IconButton>
      </Flex>
    );
  }
);
