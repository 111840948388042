import { useEffect } from "react";
import {
  HMSNotificationTypes,
  useHMSNotifications,
} from "@100mslive/react-sdk";
import { ToastBatcher } from "../Toast/ToastBatcher";
import { ToastManager } from "../Toast/ToastManager";
import { useSubscribedNotifications } from "../AppData/useUISettings";
import { SUBSCRIBED_NOTIFICATIONS } from "../../common/constants";

const notificationTypes = [
  HMSNotificationTypes.PEER_JOINED,
  HMSNotificationTypes.PEER_LEFT,
];
let toastId;
export const PeerNotifications = () => {
  const notification = useHMSNotifications(notificationTypes);
  const isPeerJoinSubscribed = useSubscribedNotifications(
    SUBSCRIBED_NOTIFICATIONS.PEER_JOINED
  );
  const isPeerLeftSubscribed = useSubscribedNotifications(
    SUBSCRIBED_NOTIFICATIONS.PEER_LEFT
  );
  useEffect(() => {
    if (!notification) {
      return;
    }
    if (notification.data.roleName === "attendee-waitingroom") {
      toastId = ToastManager.replaceToast(toastId, {
        title: notification.data.name,
        variant: "info",
        duration: 10000,
        type: "admitCard",
        peer_id: notification.data.id,
      });
    }
    ToastBatcher.showToast({ notification });
  }, [notification, isPeerJoinSubscribed, isPeerLeftSubscribed]);

  return null;
};
